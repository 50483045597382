/** @type {HTMLElement} */
const InstWr=document.querySelector('#InstWr')
/** @type {HTMLElement} */
const StaffWr=document.querySelector('#StaffWr')

/**@type {HTMLSelectElement} */
const TypeSelect=document.querySelector('#TypeSelect')
/**@type {HTMLFormElement} */
const SignupFo=document.querySelector('#SignupFo')

function isTouchDevice() {
 return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}

if(isTouchDevice()&&'IntersectionObserver' in window) {
  const frames=document.querySelectorAll('[data-frame]')
  const io=new IntersectionObserver((ents)=>{
   for(const {isIntersecting,target} of ents) {
    console.log(123,target,isIntersecting)
    if(isIntersecting) target.dataset['framing']=true
    else {
     target.dataset['framing']=null
     delete target.dataset['framing']
    }
   }
  },{ rootMargin: `-50%` })
  for(const frame of frames) {
    io.observe(frame)
  }
}

const resetWr=()=>{
  hideWr(InstWr)
  hideWr(StaffWr)
}
const hideWr=(Wr)=>{
  Wr.style.display='none'
  const items=[
    ...Wr.querySelectorAll('[data-required]'),
  ]
  for(const item of items) {
    item.required=false
  }
}
const showWr=(Wr)=>{
  Wr.style.display=null
  const items=[
    ...Wr.querySelectorAll('[data-required]'),
  ]
  for(const item of items) {
    item.required=true
  }
}
const updWr=()=>{
  const currentOption=TypeSelect.value
  if(currentOption=='Academia'){
    showWr(InstWr)
  }else if(currentOption=='Agency'||currentOption=='Software House') {
    showWr(StaffWr)
  }
}
TypeSelect.addEventListener('change',()=>{
  resetWr()
  updWr()
})

resetWr()
updWr()
console.log('you will not find any sign of the framework here. the landing is deliberately made with js' )

SignupFo.addEventListener('submit',(ev)=>{
  console.log(ev)
  ev.preventDefault()

  showSubmitting()
  setTimeout(()=>{
   showSubmit()
  },5000)
  return false
})

const showSubmitting=()=>{
 window['SubmitLa'].style.display='none'
 window['SubmitingLa'].style.display=null
 window['SubmitBu'].disabled=true
}
const showSubmit=()=>{
 window['SubmitLa'].style.display=null
 window['SubmitingLa'].style.display='none'
 window['SubmitBu'].disabled=false
}